<template>
  <div id="app">
    <router-view />
    <share></share>
    <footers v-show="flag"></footers>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
    };
  },
  mounted() {},
  created() {},
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (this.$route.query.invitation) {
          this.flag = true;
        } else {
          this.flag = false;
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="scss">
</style>
