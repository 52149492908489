import api from "@/libs/reset.js";
import store from "../store";
export default {
    //新闻列表
    async getnews({ commit }, param) {
      const res = await api.post("/Index/news", param);
      return res;
    },
    //新闻详细
    async getnewsDet({ commit }, param) {
      const res = await api.post("/Index/newsDet", param);
      res.ResponseBody.content = res.ResponseBody.content.replace(
        /\/ueditor\/php\/upload\/image/g,
        "http://xuexhadm.zpt.udao.sh.cn/ueditor/php/upload/image"
      );
      res.ResponseBody.content = res.ResponseBody.content.replace(
        /line-height: 2/g,
        "line-height:0.3rem"
      );
      // res.ResponseBody.content = res.ResponseBody.content.replace(
      //   /line-height: 1/g,
      //   "line-height:0rem"
      // );
      return res;
    },
    //收藏
    async getcollection({ commit }, param) {
     const res = await api.post("/Index/collection", param);
     return res;
    },
    //取消收藏
    async getcancel_collection({ commit }, param) {
      const res = await api.post("/Index/cancel_collection", param);
      return res;
    },
    //课程列表
    async getlesson({ commit }, param) {
     const res = await api.post("/Index/lesson", param);
     return res;
    },
    //会员权益
    async getquestion({ commit }, param) {
      const res = await api.post("/question", param);
      res.ResponseBody.content = res.ResponseBody.content.replace(
        /\/ueditor\/php\/upload\/image/g,
        "http://xuexhadm.zpt.udao.sh.cn/ueditor/php/upload/image"
      );
      res.ResponseBody.content = res.ResponseBody.content.replace(
        /line-height: 2/g,
        "line-height:0.3rem"
      );
      return res;
     },
    //轮播图
    async getbanner({ commit }, param) {
      const res = await api.post("/banner", param);
      return res;
     },
    //新闻详细
    async gettoolDet({ commit }, param) {
      const res = await api.post("/Index/toolDet", param);
      res.ResponseBody.content = res.ResponseBody.content.replace(
        /\/ueditor\/php\/upload\/image/g,
        "http://xuexhadm.zpt.udao.sh.cn/ueditor/php/upload/image"
      );
      res.ResponseBody.content = res.ResponseBody.content.replace(
        /line-height: 2/g,
        "line-height:0.3rem"
      );
      return res;
     },
    //补贴培训
    async getsocial_subsidy({ commit }, param) {
      const res = await api.post("/Index/social_subsidy", param);
      return res;
     },
      //联系我们
    async getcontactUs({ commit }, param) {
      const res = await api.post("/contactUs", param);
      res.ResponseBody.content = res.ResponseBody.content.replace(
        /\/ueditor\/php\/upload\/image/g,
        "http://xuexhadm.zpt.udao.sh.cn/ueditor/php/upload/image"
      );
      res.ResponseBody.content = res.ResponseBody.content.replace(
        /line-height: 2/g,
        "line-height:0.3rem"
      );
      return res;
     },
    //关于沪惠学
    async getabout({ commit }, param) {
      const res = await api.post("/about", param);
      res.ResponseBody.content = res.ResponseBody.content.replace(
        /\/ueditor\/php\/upload\/image/g,
        "http://xuexhadm.zpt.udao.sh.cn/ueditor/php/upload/image"
      );
      res.ResponseBody.content = res.ResponseBody.content.replace(
        /line-height: 2/g,
        "line-height:0.3rem"
      );
      return res;
     },
    //我的客户
    async getbelongTo({ commit }, param) {
      const res = await api.post("/Index/belongTo", param);
      return res;
     },
    //个人信息
    async getinformation({ commit }, param) {
      const res = await api.post("/Index/information", param);
      return res;
     },
    //协议
    async getagreement({ commit }, param) {
      const res = await api.post("/agreement", param);
      return res;
     },
    //修改个人信息
    async getinformation_up({ commit }, param) {
      const res = await api.post("/Index/information_up", param);
      return res;
     },
     //课程详细
    async getlessonDet({ commit }, param) {
      const res = await api.post("/Index/lessonDet", param);
      res.ResponseBody.lesson.content = res.ResponseBody.lesson.content.replace(
        /\/ueditor\/php\/upload\/image/g,
        "http://xuexhadm.zpt.udao.sh.cn/ueditor/php/upload/image"
      );
      res.ResponseBody.lesson.content = res.ResponseBody.lesson.content.replace(
        /line-height: 2/g,
        "line-height:0.3rem"
      );
      return res;
     },
    //在线报名
    async getenroll({ commit }, param) {
      const res = await api.post("/Index/enroll", param);
      return res;
     },
    //我的提问
    async getsuggest({ commit }, param) {
      const res = await api.post("/Index/suggest", param);
      return res;
     },
    //提问
    async getsuggestAdd({ commit }, param) {
      const res = await api.post("/Index/suggestAdd", param);
      return res;
     },
      //根据邀请码获取信息接口
    async getinvitationInfo({ commit }, param) {
      const res = await api.post("/Index/invitationInfo", param);
      return res;
     },
    //默认销售接口
    async getdefaultSale({ commit }, param) {
      const res = await api.post("/defaultSale", param);
      return res;
     },
    //咨询量接口
    async getconsultingInc({ commit }, param) {
      const res = await api.post("/Index/consultingInc", param);
      return res;
     },
    //推荐量接口
    async getrecommendInc({ commit }, param) {
      const res = await api.post("/Index/recommendInc", param);
      return res;
     },
    //满意度
    async getsatisfiedInc({ commit }, param) {
      const res = await api.post("/Index/satisfiedInc", param);
      return res;
     },
      //转发接口
    async getforward({ commit }, param) {
      const res = await api.post("/Index/forward", param);
      return res;
     },
      //微信登录
    async getWxLogin({ commit }, param) {
      const res = await api.post1("/Index/wx_login", param);
      return res;
     },
    //wx分享
    async getSignPackage({ commit }, param) {
      const res = await api.post1("/Index/getSignPackage", param);
      return res;
    },
    //wx绑定手机号
    async getbinding({ commit }, param) {
      const res = await api.post1("/Index/binding", param);
      return res;
    },
   
    //新闻转发接口
    async getnewsForward({ commit }, param) {
      const res = await api.post("/Index/newsForward", param);
      return res;
    },
     //收藏列表接口
     async getcollectionList({ commit }, param) {
      const res = await api.post("/Index/collectionList", param);
      return res;
    },
}