const state = {
  //个人信息
  userinfo:'',
  //销售信息
  invitationinif:'',
  //居住证积分模拟结果
  scoreresultslist:[],
  //微信openid
  wx:{},
  //邀请码
  invitation:'',
  //首次进入链接
  login_path:"",
  //分享的参数
  shareinformation:{
    title:'',
    imgurl:'',
    type:''
  },
  // 上一级路由
  path:{},
  //判断第一次注册之后返回新闻页flag == 1 正常flag == 2 需要返回
  flag: 1
}
export default state;