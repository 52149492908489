<template>
  <div id="nav">
    <img src="../assets/username.png" alt="" @click="user" />
    <span>{{ $route.meta.title }}</span>
  </div>
</template>

<script>
export default {
  methods: {
    user() {
      this.$router.push("/user");
    },
  },
};
</script>

<style lang='scss' scoped>
#nav {
  display: flex;
  align-items: center;
  width: 7.5rem;
  height: 0.88rem;
  line-height: 0.88rem;
  img {
    margin-left: 0.36rem;
    vertical-align: middle;
    width: 0.64rem;
    height: 0.64rem;
    background: linear-gradient(180deg, #3e87ff 0%, #97b1ff 100%);
    box-shadow: 0rem 0.02rem 0.1rem 0rem rgba(8, 179, 255, 0.55);
    border-radius: 0.32rem;
  }
  span {
    margin-left: 2.08rem;
    font-size: 0.34rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141f33;
  }
}
</style>