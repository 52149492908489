<template>
  <div></div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      path: "",
      new_id: "",
      querys: {},
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
      shareinformation: (state) => state.shareinformation,
    }),
  },
  methods: {
    ...mapActions([
      "getforward",
      "getSignPackage",
      "getinvitationInfo",
      "getnewsForward",
    ]),
    //分享朋友和朋友圈
    getshare() {
      var urls = window.location.href;
      let that = this;
      let num = 0;
      for (const key in this.querys) {
        num++;
      }
      //判断是否有参数进行不同的拼接
      if (this.userinfo) {
        if (num > 0) {
          var url = urls + `&invitation=${this.userinfo.invitation}`;
        } else {
          var url = urls + `?invitation=${this.userinfo.invitation}`;
        }
      } else {
        if (num > 0) {
          var url = urls + `&invitation=${this.invitationinif.invitation}`;
        } else {
          var url = urls + `?invitation=${this.invitationinif.invitation}`;
        }
      }
      //根据不同页面定制不同的分享内容
      let title = "";
      let img = "";
      if (that.path == "/policydetails") {
        return;
        title = this.shareinformation.title;
        img = this.shareinformation.imgurl;
      } else if (that.path == "/traindetails") {
        return;
        title = this.shareinformation.title;
        img = this.shareinformation.imgurl;
      } else if (that.path == "/instrumentdetails") {
        return;
        title = this.shareinformation.title;
        img =
          "https://xuexh.zpt.udao.sh.cn/file/62fb49698aa7fe726dd708d913ba27b7.jpg";
      } else {
        title = "沪惠学";
        img =
          "https://xuexh.zpt.udao.sh.cn/file/62fb49698aa7fe726dd708d913ba27b7.jpg";
      }

      this.getSignPackage({
        url: urls,
      }).then((res) => {
        if (res.SuccessCode == 200) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.ResponseBody.appId, // 必填，公众号的唯一标识
            timestamp: res.ResponseBody.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.ResponseBody.nonceStr, // 必填，生成签名的随机串
            signature: res.ResponseBody.signature, // 必填，签名
            jsApiList: [
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "updateAppMessageShareData",
              "updateTimelineShareData",
            ], // 必填，需要使用的JS接口列表
          });
          wx.ready(function (res) {
            wx.onMenuShareTimeline({
              title: title, // 分享标题
              desc: "沪惠学", // 分享描述
              link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
              imgUrl: img, // 分享图标
              success: function () {
                //记录分享次数
                if (that.userinfo) {
                  that.getforward({
                    member_id: that.userinfo.id,
                    invitation: that.userinfo.invitation,
                    type: "2",
                  });
                }

                if (that.path == "/policydetails") {
                  that.getnewsForward({
                    id: that.new_id,
                  });
                }
              },
            });

            wx.onMenuShareAppMessage({
              title: title, // 分享标题
              desc: "沪惠学", // 分享描述
              link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
              imgUrl: img, // 分享图标
              success: function () {
                //记录分享次数
                if (that.userinfo) {
                  that.getforward({
                    member_id: that.userinfo.id,
                    invitation: that.userinfo.invitation,
                    type: "2",
                  });
                }

                if (that.path == "/policydetails") {
                  that.getnewsForward({
                    id: that.new_id,
                  });
                }
              },
            });
            // wx.updateAppMessageShareData({
            //   title: "沪惠学", // 分享标题
            //   desc: "描述描述描述描述", // 分享描述
            //   link: urls, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
            //   imgUrl:
            //     "https://xuexh.zpt.udao.sh.cn/file/8ba6e34e2e02e7d25a37449f0b7b08ec.jpg", // 分享图标
            //   success: function () {
            //     console.log(11111);
            //   },
            // });
            // wx.updateTimelineShareData({
            //   title: "沪惠学", // 分享标题
            //   link: urls, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            //   imgUrl:
            //     "https://xuexh.zpt.udao.sh.cn/file/8ba6e34e2e02e7d25a37449f0b7b08ec.jpg", // 分享图标
            //   success: function () {
            //     console.log(22222);
            //   },
            // });
          });
          wx.error(function (eer) {
            console.log(eer, 3333);
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        }
      });
    },
  },
  mounted() {},
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.$store.commit("SET_path", oldVal);
        var urls = window.location.href;
        //动态监听是否有邀请码
        if (this.$route.query.invitation) {
          this.$store.commit("SET_login_path", urls.split("invitation=")[0]);
          this.$store.commit("SET_invitation", this.$route.query.invitation);
          this.getinvitationInfo({
            invitation: this.$route.query.invitation,
          }).then((res) => {
            this.getforward({
              member_id: res.ResponseBody.id,
              invitation: this.$route.query.invitation,
              type: "1",
            });
          });
        }
        this.path = val.path;
        this.querys = this.$route.query;
        if (this.$route.query.id) {
          this.new_id = this.$route.query.id;
        }
        //延迟调用分享，等待数据传回
        setTimeout(() => {
          this.getshare();
        }, 500);
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang='scss' scoped>
</style>