<template>
  <div>
    <div class="box">
      <img class="logo" src="../assets/logo.png" alt="" />
      <div class="txt">
        <div class="a">沪惠学</div>
        <div class="b">政府补贴培训资讯领航者</div>
      </div>
      <!-- <button class="btn">打开小程序</button> -->
      <wx-open-launch-weapp
        id="launch-btn"
        username="gh_cf2bfa89a6f8"
        :path="url"
      >
        <script type="text/wxtag-template">
          <style>
            .btn {
              width: 110px;
              height: 40px;
              background: #2a78ee;
              border-radius: 7px;
              color: #fff;
              margin-left: 20px;
              font-size: 14px;
              border-radius: 10px;
              border: 0 none;
            }
          </style>
          <button class="btn">打开小程序</button>
        </script>
      </wx-open-launch-weapp>
    </div>
    <div class="mask" v-if="flag">
      <div class="popup aniamtion">
        <div class="content">
          <img class="header" src="../assets/popup.png" alt="" />
          <div class="bottom">
            <div class="n1">— 前往小程序 —</div>
            <div class="n2">登录后获得以下特权</div>
            <div class="n3">
              1.<span style="color: #ff0000">7*12</span>专属教育客服为你服务;
            </div>
            <div class="n4">2.掌握第一手补贴福利政策;</div>
            <div class="n5">3.多项会员特权等</div>
            <!-- <button class="btn1">前往小程序</button> -->
            <div class="xx">
              <wx-open-launch-weapp
                id="launch-btn"
                username="gh_cf2bfa89a6f8"
                :path="url"
              >
                <script type="text/wxtag-template">
                  <style>
                    .btn1 {
                      display: block;
                      margin: 0 auto;
                      width: 250px;
                      height: 40px;
                      background-image: linear-gradient(to bottom right, #31deed, #1b98ff);
                      border-radius: 20px;
                      border: 0 none;
                      font-size: 20px;
                      color: #fff;
                    }
                  </style>
                  <button class="btn1">打开小程序</button>
                </script>
              </wx-open-launch-weapp>
            </div>
          </div>
          <img
            class="del"
            @click="flag = false"
            src="../assets/del.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      url: "",
      flag: true,
    };
  },
  methods: {
    ...mapActions(["getSignPackage"]),
    getopen() {
      var urls = window.location.href;
      let arr = this.login_path.replace(/=/g, "~");
      arr = arr.replace("?", "!");
      this.url = `pages/index/index?first_login_path=${arr}invitation=${this.$route.query.invitation}`;
      console.log(this.url);
      this.getSignPackage({ url: urls }).then((res) => {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.ResponseBody.appId, // 必填，公众号的唯一标识
          timestamp: res.ResponseBody.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.ResponseBody.nonceStr, // 必填，生成签名的随机串
          signature: res.ResponseBody.signature, // 必填，签名
          jsApiList: ["onMenuShareTimeline"],
          openTagList: ["wx-open-launch-weapp"], // 必填，需要使用的JS接口列表
        });
      });
    },
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitation: (state) => state.invitation,
      invitationinif: (state) => state.invitationinif,
      login_path: (state) => state.login_path,
    }),
  },
  mounted() {
    var btn = document.getElementById("launch-btn");
    btn.addEventListener("launch", function (e) {
      console.log("success");
    });
    btn.addEventListener("error", function (e) {
      console.log("fail", e.detail);
    });
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (this.$route.query.invitation) {
          if (this.userinfo == "") {
            this.flag = false;
            setTimeout(() => {
              this.flag = true;
            }, 2000);
          } else {
            this.flag = false;
          }
          setTimeout(() => {
            this.getopen();
          }, 500);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang='scss' scoped>
.xx {
  display: flex;
  justify-content: center;
}
.box {
  width: 7.1rem;
  padding: 0.24rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 100000;
  left: 0.2rem;
  bottom: 0.2rem;
  height: 1.3rem;
  background: #f1f1f1;
  border-radius: 0.05rem;
  border: 0.01rem solid #e7e7e7;
  .logo {
    width: 0.64rem;
    height: 0.64rem;
    border-radius: 50%;
  }
  .txt {
    .a {
      margin-left: 0.3rem;
      font-size: 0.28rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #142033;
    }
    .b {
      margin-left: 0.3rem;

      font-size: 0.24rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #888888;
    }
  }
  .btn {
    width: 110px;
    height: 40px;
    background: #2a78ee;
    border-radius: 7px;
    color: #fff;
    margin-left: 20px;
    font-size: 14px;
    border-radius: 10px;
    border: 0 none;
  }
}
.mask {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #616161, $alpha: 0.6);
}
.popup {
  position: relative;
  border-radius: 0.15rem;
  margin-top: -1rem;
  // overflow: hidden;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -60%);
  .content {
    width: 6.17rem;
    height: 7.14rem;
    .header {
      height: 2rem;
      width: 100%;
      vertical-align: middle;
    }
    .bottom {
      overflow: hidden;
      height: 5.13rem;
      background-color: #fff;
      border-radius: 0 0 0.15rem 0.15rem;
      .n1 {
        font-size: 0.3rem;
        text-align: center;
        margin: 0.42rem 0 0 0.24rem;
      }
      .n2 {
        font-size: 0.34rem;
        text-align: center;
        margin: 0.2rem 0 0 0.24rem;
      }
      .n3,
      .n4,
      .n5 {
        margin-left: 1rem;
        font-size: 0.3rem;
        margin-bottom: 0.25rem;
      }
      .n3 {
        margin-top: 0.6rem;
      }
      .btn1 {
        display: block;
        margin: 0 auto;
        width: 250px;
        height: 40px;
        background-image: linear-gradient(to bottom right, #31deed, #1b98ff);
        border-radius: 20px;
        border: 0 none;
        font-size: 20px;
        color: #fff;
      }
    }
    .del {
      display: block;
      margin: 0.56rem auto 0;
      width: 0.72rem;
      height: 0.72rem;
    }
  }
}

.aniamtion {
  animation: mymove 0.5s;
  /* //infinite属性是表示无限循环的意思，没有这个属性的话动画只执行一次。 */
}

@keyframes mymove {
  from {
    /* left: 0px; */
    /* transform: rotate(7deg) skew(50deg) translate(30rpx,30rpx); */
    transform: scale(0.5);
  }

  to {
    /* left: 200px; */
    /* transform: rotate(7deg) skew(5deg) translate(100rpx,100rpx); */
    transform: scale(1);
  }
}
</style>