<template>
  <div>
    <div class="list">
      <router-link to="/" class="item">
        <img :src="$route.path == '/' ? n6 : n1" alt="" />
        <div>政策普及</div>
      </router-link>
      <router-link to="/train" class="item">
        <img :src="$route.path == '/train' ? n7 : n2" alt="" />
        <div>补贴培训</div>
      </router-link>
      <router-link to="/textual" class="item">
        <img :src="$route.path == '/textual' ? n8 : n3" alt="" />
        <div>我要内卷</div>
      </router-link>
      <router-link to="/instrument" class="item">
        <img :src="$route.path == '/instrument' ? n10 : n5" alt="" />
        <div>查询工具</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      n1: require("../assets/111.png"),
      n2: require("../assets/222.png"),
      n3: require("../assets/333.png"),
      n5: require("../assets/555.png"),
      n10: require("../assets/1010.png"),
      n6: require("../assets/666.png"),
      n7: require("../assets/777.png"),
      n8: require("../assets/888.png"),
    };
  },
  mounted() {},
};
</script>

<style lang='scss' scoped>
.list {
  position: fixed;
  bottom: 0;
  z-index: 2000;
  width: 7.5rem;
  height: 1.44rem;
  background: #ffffff;
  box-shadow: 0px -0.04rem 0.08rem 0rem #f5f7fa;
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    width: 0.5rem;
    margin-bottom: 0.1rem;
  }
  .item {
    font-size: 0.2rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #616b80;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>