export default {
  //登录信息
  SET_USERINFO(state, data) {
    state.userinfo = data;
  },
   //登录信息type
   SET_USERINFOTYPE(state, data) {
    state.userinfo.type = data;
  },
  //销售信息
  SET_INVITATIONINIF(state, data) {
    state.invitationinif = data;
  },
   //居住证积分模拟结果scoreresultslist
   SET_SCORERESSULTSLIST(state, data) {
    state.scoreresultslist = data;
  },
   //wx
  SET_WX(state, data) {
    state.wx = data;
  },
  //邀请码
  SET_invitation(state, data) {
    state.invitation = data;
  },
   //分享信息
   SET_shareinformation(state, data) {
    state.shareinformation = data;
  },
  //首次进入链接
  SET_login_path(state, data) {
    state.login_path = data;
  },
  //上一级路径
  SET_path(state, data) {
    state.path = data;
  },
  SET_FALG(state, data) {
    state.flag = data;
  },
}