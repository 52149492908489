import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: "/",
    name: "home",
    component: (resolve) => require(["@/views/Home.vue"], resolve),
    meta: {
      title: "政策资讯",
    },
  },
  {
    path: "/train",
    name: "train",
    component: (resolve) => require(["@/views/train.vue"], resolve),
    meta: {
      title: "补贴培训",
    },
  },
  {
    path: "/textual",
    name: "textual",
    component: (resolve) => require(["@/views/textual.vue"], resolve),
    meta: {
      title: "我要内卷",
    },
  },
  {
    path: "/instrument",
    name: "instrument",
    component: (resolve) => require(["@/views/instrument.vue"], resolve),
    meta: {
      title: "查询工具",
    },
  },
  {
    path: "/policydetails",
    name: "policydetails",
    component: (resolve) => require(["@/views/policydetails.vue"], resolve),
    meta: {
      title: "新闻详细",
    },
  },
  {
    path: "/rights",
    name: "rights",
    component: (resolve) => require(["@/views/rights.vue"], resolve),
    meta: {
      title: "会员权益",
    },
  },
  {
    path: "/instrumentdetails",
    name: "instrumentdetails",
    component: (resolve) => require(["@/views/instrumentdetails.vue"], resolve),
    meta: {
      title: "新闻详细",
    },
  },
  {
    path: "/examination",
    name: "examination",
    component: (resolve) => require(["@/views/examination.vue"], resolve),
    meta: {
      title: "考试申报",
    },
  },
  {
    path: "/subsidy",
    name: "subsidy",
    component: (resolve) => require(["@/views/subsidy.vue"], resolve),
    meta: {
      title: "补贴培训",
    },
  },
  {
    path: "/user",
    name: "user",
    component: (resolve) => require(["@/views/user.vue"], resolve),
    meta: {
      title: "我的",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: (resolve) => require(["@/views/contact.vue"], resolve),
    meta: {
      title: "联系我们",
    },
  },
  {
    path: "/regards",
    name: "regards",
    component: (resolve) => require(["@/views/regards.vue"], resolve),
    meta: {
      title: "关于沪惠学",
    },
  },
  {
    path: "/client",
    name: "client",
    component: (resolve) => require(["@/views/client.vue"], resolve),
    meta: {
      title: "我的客户",
    },
  },
  {
    path: "/agreement",
    name: "agreement",
    component: (resolve) => require(["@/views/agreement.vue"], resolve),
    meta: {
      title: "协议",
    },
  },
  {
    path: "/applyfor",
    name: "applyfor",
    component: (resolve) => require(["@/views/applyfor.vue"], resolve),
    meta: {
      title: "渠道申请",
    },
  },
  {
    path: "/traindetails",
    name: "traindetails",
    component: (resolve) => require(["@/views/traindetails.vue"], resolve),
    meta: {
      title: "课程详细",
    },
  },
  {
    path: "/myuser",
    name: "myuser",
    component: (resolve) => require(["@/views/myuser.vue"], resolve),
    meta: {
      title: "我的信息",
    },
  },
  {
    path: "/myfeedback",
    name: "myfeedback",
    component: (resolve) => require(["@/views/myfeedback.vue"], resolve),
    meta: {
      title: "我的反馈",
    },
  },
  {
    path: "/suggest",
    name: "suggest",
    component: (resolve) => require(["@/views/suggest.vue"], resolve),
    meta: {
      title: "建议与反馈",
    },
  },
  {
    path: "/Channels",
    name: "Channels",
    component: (resolve) => require(["@/views/Channels.vue"], resolve),
    meta: {
      title: "销售详细",
    },
  },
  {
    path: "/integrating",
    name: "integrating",
    component: (resolve) => require(["@/views/integrating.vue"], resolve),
    meta: {
      title: "积分模拟系统",
    },
  },
  {
    path: "/rzhuanhu",
    name: "rzhuanhu",
    component: (resolve) => require(["@/views/rzhuanhu.vue"], resolve),
    meta: {
      title: "居转户系统",
    },
  },
  {
    path: "/condition",
    name: "condition",
    component: (resolve) => require(["@/views/condition.vue"], resolve),
    meta: {
      title: "居转户系统测试结果",
    },
  },
  {
    path: "/scoreresults",
    name: "scoreresults",
    component: (resolve) => require(["@/views/scoreresults.vue"], resolve),
    meta: {
      title: "积分模拟系统结果",
    },
  },
  {
    path: "/bindPhone",
    name: "bindPhone",
    component: (resolve) => require(["@/views/bindPhone.vue"], resolve),
    meta: {
      title: "绑定手机",
    },
  },
  {
    path: "/collect",
    name: "collect",
    component: (resolve) => require(["@/views/collect.vue"], resolve),
    meta: {
      title: "我的收藏",
    },
  },


]

const router = new VueRouter({
  mode: "history", //hash
  history: true,
  strict: process.env.NODE_ENV !== "production",
  routes
})

export default router
