<template>
  <div>
    <div class="ganduo" v-dialogDrag ref="ganduo">
      <img @click="go" :src="invitationinif.picture" class="imgs" />
      <div @click="go" class="zx">微信咨询</div>
      <a :href="`tel:${invitationinif.phone}`">
        <img
          style="box-sizing: border-box; display: block"
          src="../assets/dianhua1.png"
        />
      </a>

      <img
        @click="fx"
        v-show="flag1"
        style="box-sizing: border-box; display: block"
        src="../assets/wxfx.png"
      />

      <img
        @click="ts"
        v-show="flag1"
        style="box-sizing: border-box; display: block"
        src="../assets/txjy.png"
      />

      <div class="gonglang" style="margin-left: 0.5rem" @click="getshow">
        <img
          v-show="!flag1"
          src="../assets/jiahao.png"
          style="
            margin-left: 0rpx;
            padding: 0rpx;
            width: 0.9rem;
            height: 0.9rem;
          "
          alt=""
        />

        <img
          v-show="flag1"
          src="../assets/guanbi.png"
          style="
            margin-left: 0rem;
            padding: 0rpx;
            width: 0.9rem;
            height: 0.9rem;
          "
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      flag1: false,
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
    }),
  },
  methods: {
    ...mapActions(["getdefaultSale", "getinformation", "getinvitationInfo"]),
    getshow() {
      this.flag1 = !this.flag1;
    },
    go() {
      this.$router.push("/Channels");
    },
    fx() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信浏览器
        this.$toast("点击右上角...进行分享");
      } else {
        //非微信浏览器
        this.$toast("请在手机微信端运行");
      }
    },
    ts() {
      this.$router.push({
        path: "/suggest",
        query: {
          is_type: 2,
        },
      });
    },
  },
  mounted() {
    let that = this;
    console.log(this.$route.query.invitation, "invitation");
    if (!that.userinfo) {
      if (that.$route.query.invitation) {
        that
          .getinvitationInfo({
            invitation: that.$route.query.invitation,
          })
          .then((res) => {
            if (res.SuccessCode == 200) {
              if (res.ResponseBody.type == 1 || res.ResponseBody.type == 5) {
                that.$store.commit("SET_INVITATIONINIF", res.ResponseBody);
              } else if (res.ResponseBody.type == 3) {
                if (!res.ResponseBody.invited) {
                  // 没有就执行这里，显示默认销售
                  if (res.ResponseBody.sale_invitation) {
                    that.$store.commit("SET_INVITATIONINIF", res.ResponseBody);
                  } else {
                    that.getdefaultSale({}).then((res) => {
                      that.$store.commit(
                        "SET_INVITATIONINIF",
                        res.ResponseBody
                      );
                    });
                  }
                } else {
                  // 接口报错执行201//原因应该老账号原因，
                  if (res.SuccessCode == 201) {
                    that.$store.commit("SET_INVITATIONINIF", res.ResponseBody);
                  } else {
                    //有邀请码执行这个
                    //判断渠道会员是否有上级销售
                    if (res.ResponseBody.sale_invitation) {
                      // 显示上级销售信息
                      that
                        .getinvitationInfo({
                          invitation: res.ResponseBody.sale_invitation,
                        })
                        .then((eee) => {
                          that.$store.commit(
                            "SET_INVITATIONINIF",
                            eee.ResponseBody
                          );
                        });
                    } else {
                      that.$store.commit(
                        "SET_INVITATIONINIF",
                        res.ResponseBody
                      );
                    }
                  }
                }
              }
            }
          });
      } else {
      }
    }
    // if (this.invitationinif == "") {
    //   this.getdefaultSale({}).then((res) => {
    //     this.$store.commit("SET_INVITATIONINIF", res.ResponseBody);
    //   });
    // }
  },
};
</script>

<style lang='scss' scoped>
.ganduo .imgs {
  border: 0.08rem solid #fff;
  box-sizing: border-box;
  width: 1.06rem;
  height: 1.06rem;
  margin-left: 0.4rem;
  border-radius: 50%;
  transform: translateY(0.4rem);
}

.ganduo {
  position: fixed;
  transform: scale(0.8);
  right: 0.1rem;
  bottom: 2rem;
  z-index: 200;
}

.ganduo .zx {
  position: relative;
  z-index: 1000;
  margin-left: 0.3rem;
  text-align: center;
  color: #fff;
  font-size: 0.24rem;
  width: 1.3rem;
  height: 0.47rem;
  line-height: 0.47rem;
  background: #335bff;
  border-radius: 0.25rem;
}

.ganduo img {
  margin-left: 0.2rem;
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
}

.gonglang {
  box-shadow: 0px 0.02rem 0.1rem 0px rgba(0, 0, 0, 0.19);
  border-radius: 0.48rem;

  width: 0.9rem;
  height: 0.9rem;
}
.gonglang img {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0rem;
  box-sizing: border-box;
}
</style>