<template>
  <div>
    <van-nav-bar
      placeholder
      fixed
      :title="title"
      left-arrow
      @click-left="onClickLeft"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["title", "url"],
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
      invitation: (state) => state.invitation,
      shareinformation: (state) => state.shareinformation,
      path: (state) => state.path,
      flag: (state) => state.flag,
    }),
  },
  methods: {
    onClickLeft() {
      if (this.url) {
        this.$router.push(this.url);
      } else {
        // if (this.path.matched.length == 0) {
        //   this.$router.push("/");
        // } else {
        //   if (this.flag == 2) {
        //     this.$store.commit("SET_FALG", 1);
        //     this.$store.commit("SET_login_path", "");
        //     this.$router.push("/");
        //   } else {
        //     if (this.invitation == "") {
        //       this.$router.go(-1);
        //     } else {
        //       this.$toast("前往小程序查看更多");
        //     }
        //   }
        // }

        if (this.flag == 2) {
          this.$store.commit("SET_FALG", 1);
          this.$store.commit("SET_login_path", "");
          this.$router.push("/");
        } else {
          if (!this.$route.query.invitation) {
            this.$router.go(-1);
          } else {
            this.$toast("前往小程序查看更多");
          }
        }
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.box {
  padding: 0.25rem;
  box-sizing: border-box;
}
.title {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
</style>