import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from 'vant';
import '../style/index.css'
import VueScroller from 'vue-scroller'
import 'vant/lib/index.css';
Vue.use(VueScroller)
Vue.use(vant);
Vue.config.productionTip = false
import directive from "./libs/directive.js";
//引入全局组件
import nav from "./components/nav.vue";
import tbrs from "./components/tbr.vue";
import consult from "./components/consult.vue";
import navbar from "./components/navbar.vue";
import share from "./components/share.vue";
import footer from "./components/footer.vue";
Vue.component("navs", nav);
Vue.component("tbrs", tbrs);
Vue.component("consult", consult);
Vue.component("navbar", navbar);
Vue.component("share", share);
Vue.component("footers", footer);
//引入百度地图
import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  ak: "hA23kuotRHq2xoQDhakYi6NrAVSZUge7",
});
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
